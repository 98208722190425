import React from "react";
import styled from "@emotion/styled";

import OnfidoLogo from "../images/onfido.svg";
import WhoElseLogo from "../images/whoelse.png";
import SwingDevLogo from "../images/swingdev.svg";
import JohnPitcherLogo from "../images/johnpitcher.png";
import ConfectedLogo from "../images/confected.png";

const ListElementContent = styled.div`
background: #111;
  margin: 1em 30px;
  padding: 0em 1em 2em 1em;
  color: #FFF;
  border: 1px solid #FFF;
  border-top-width: 0;
  font-size: 24px;
  line-height: 30px;
  clear: both;
  overflow: hidden;
`;

const TimeLine = styled.ul`

  li {
    list-style: none;
    margin: 2em 0;
    padding: 0 2em 3em 2em;
    position: relative;
    font-size: 0;
    line-height: 0;
    &:before {
      content: '';
      display: block;
      position: absolute;
      top: 10px;
      left: -8px;
      width: 20px;
      height: 20px;
      border-radius: 50%;
      background: #FFF;
    }
  }

  date {
    font-size: 0.6em;
    font-family: sans-serif;
    background: #FFF;
    min-width: 100%;
    color: #000;
    display: block;
    margin: 0 -2em;
    margin-bottom: 2em;
    padding: 0.2em 2em;
    font-weight: bold;
    text-transform: uppercase;
  }

  h2 {
    line-height: 1.5;
    margin: 0;
    img {
      max-width: 250px;
      margin-bottom: 0.5em;
    }
  }
  h3 {
    line-height: 1;
    font-weight: 200;
    margin-bottom: 0.5em;
  }
  p {
    font-weight: 200;
    font-style: italic;
    font-size: 0.8em;
    opacity: 0.7;

    &::after {
      content: '';
      display: block;
      width: 100%;
      height: 1px;
      background: #444;
      margin-top: 0.5em;
      margin-bottom: 2em;
    }

    &:last-child::after {
      display: none;
    }
  }
  margin: 1em;
  font-size: 18px;
  padding: 1em 0;
  position: relative;
  &::before {
    content: '';
    width: 5px;
    top: 0;
    bottom: 0;
    left: 0;
    background: #FFF;
    display: block;
    position: absolute;
  }

  @media screen and (min-width: 1200px) {
      width: 40%;
      margin-left: 50%;
    li:nth-child(2n) {
      transform: translateX(-100%);
      text-align: right;
      &::before {
        left: auto;
        right: -12px;
      }
    }
  }
`;

export default () => (<TimeLine>
  <li>
    <ListElementContent>
  <date>2019 - currently</date> 
      <h2><img src={OnfidoLogo} alt="Onfido" /></h2>

      <h3>Senior Software Engineer</h3>
      <p>After getting promoted to the Senior role I have built a new Nest.js / TypeScript backend service from scratch. I led the process throughout the whole lifecycle from choosing proper technology and advocating it internally to releasing it and handing it off to another team for maintenance. I was also involved in the recruitment process and conducted technical interviews, was actively participating in the scrum process, and helped in road-mapping the future of the project.</p>

      <h3>Software Engineer</h3>
      <p>In this role, I was responsible for React Native cross-platform mobile app showcasing company SDK. I maintained the app, implemented new features, refactored old code and migrated it to TypeScript, reworked e2e, and build pipelines. I also maintained our technical documentation and migrated it to Gatsby. I also wrote several technical articles and gave talks on internal front-end meetings.</p>
    </ListElementContent>
  </li>
  <li>
    <ListElementContent>
      <date>2014 - 2018</date>
      <h2><img src={SwingDevLogo} alt="SwingDev" /></h2>

      <h3>Developer Evangelist</h3>
      <p>As Developer Evangelist I promoted technologies used by the company and helped recruit new talents by writing technical articles, running React workshops for 20+ developers, and conducting technical interviews.</p>

      <h3>Senior JavaScript Developer</h3>
      <p>As a Senior JavaScript Developer, I worked on multiple projects ranging across technologies and industries including frontend dashboard for music promoters for one of the biggest ticket resellers, frontend and backend portfolio manager for Venture Capital company, video streaming backend supporting high-load of users. I used multiple technologies including JavaScript, TypeScript, Python, GoLang, Node.js, Express, Nest.js, React, Angular.js, Postgresql, AWS, SQS, Lambda, EC2, S3, CloudFront.</p>
    </ListElementContent>
  </li>
  <li>
    <ListElementContent>
      <date>2014.04-07</date>
      <h2><img src={WhoElseLogo} alt="WhoElse" /></h2>
      <h3>Android Developer</h3>
      <p>I worked in a team of 4 on an Android application with a 50k+ userbase.</p>
    </ListElementContent>
  </li>
  <li>
    <ListElementContent>
      <date>2011-2014</date>
      <h2><img src={JohnPitcherLogo} art="John Pitcher Interactive" /></h2>
      <h3>Web &amp; Mobile Developer</h3>
      <p>My work ranged from creating landing pages, online contests, marketing emails to building a location-based Android app for navigating through the venue.</p>

      <h3>Junior PHP / JavaScript Developer</h3>
      <p>I created various mailing templates, online contests, backends in PHP/ZenFramework.</p>
    </ListElementContent>

  </li>
  <li>
    <ListElementContent>
      <date>2011-2013</date>
      <h2><img src={ConfectedLogo} alt="Confected.com" /></h2>
      <h3>Product Developer</h3>
      <p>In this startup, I worked on a conference management system used in various venues like TEDxWarsaw and ICL.</p>
    </ListElementContent>
  </li>
  <li>
    <ListElementContent>
    <date>2007-2014</date>
    <h2>Various Companies</h2>
    <h3>Freelancer</h3>
    <p>I freelanced for multiple companies creating landing pages, backends, Angular, and React frontends.</p>
    </ListElementContent>
  </li>
</TimeLine>);