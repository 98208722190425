import React from "react";
import styled from "@emotion/styled";
import { useMediumData } from "./hook";

const PostsElement = styled.ul`
    list-style: none;
    margin: 0 5%;
    padding: 0;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 2em;

    @media screen and (max-width: 1200px) {
        grid-template-columns: repeat(1, 1fr);
    }

    @media screen and (min-width: 1600px) {
        grid-template-columns: repeat(3, 1fr);
    }

    & > li:first-child {
        grid-column-end: span 2;
        grid-row-end: span 2;

    @media screen and (max-width: 1200px) {
        grid-column-end: span 1;
        grid-row-end: span 1;
    }
    }
`;

const PostElement = styled.li`
    margin: 0;
    padding: 0;
    color: #000;
    padding-bottom: 1em;
    margin-bottom: 1.5em;
    a {
        display: flex;
        flex-direction: column;
        height: 100%;
        line-height: 0;
        text-decoration: none;
        position: relative;
        color: inherit;
        background: #FFF;
        transition: all 0.3s 0.1s ease-out;
        &:hover {
            background: #000;
            .content {
                color: #FFF;
            }

            img {
            }
        }
    }

    h3 {
        font-weight: 200;
        padding: 0;
    }
    img {
        width: 100%;
        padding: 0;
        margin: 0;
        transition: all 0.2s ease-out;
    }
    date {
        /* position: absolute;
        top: -10px;
        left: -10px;
        font-size: 130px;
        color: #EEE; */
    }
    .content {
        line-height: 1.5;
        transition: all 0.3s 0.1s ease-in;
        padding: 2em 1em;
        margin-top: auto;
    }

    .details {
        list-style: none;
        margin: 0;
        padding: 0;
        li {
            padding: 0 1em 0 0;
            display: inline;
        }
    }

`;

const Image = styled.div`
    flex: 1;
    width: 100%;
    background: url(${({ src}) => src});
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
    min-height: 200px;
    @media screen and (max-width: 1600px) and (min-width: 1000px) {
        min-height: 400px;
    }
`;

const formatDate = (d) => {
    const dd = new Date(d);
    return dd.toLocaleDateString("en-GB");
}

const Post = ({data}) => (
    <PostElement><a href={data.Link}>
        <Image src={data.Picture} />
        <div className="content">
            <date>{formatDate(data.Publish_Date)}</date>
            <h3>{data.Title}</h3>
            </div>
    </a></PostElement>
)

export default () => {
    let posts = useMediumData();
    if (!posts) {
        return null;
    }
    console.log(posts);
    return (
        <PostsElement>
            {posts.allMediumArticles.edges.map(({ node }) => <Post data={node} key={node.id} />)}
        </PostsElement>
    );
}
