import React from "react"
import styled from "@emotion/styled";

import Layout from "../components/layout"
import SEO from "../components/seo"

import PaddedSection from "../components/sections/paddedSection";
import HeroSection from "../components/sections/hero";
import Canvas from "../components/canvas";
import Articles from "../components/articles";
import TimeLine from "../components/timeline";
import { AboutMe } from "../components/aboutMe";


const HeroContent = styled.div`
  position: relative;
  z-index: 2;
  text-align: center;
`;

const ContentWrapper = styled.div`
  text-transform: uppercase;
  h1 {
    text-shadow: 15px 0 0 #000;
  }

  h2 {
    text-shadow: 2px 0 0 #000;
  }
`;

const IsometricContainer = styled.div`
/* transform: skew(63deg,-26.6deg);
  h1 {
    transform: skew(-63deg) scale(1,.5);
  } */
`;

const ContactSection = styled.div`
  font-size: 30px;
  a {
    color: inherit;
  }
`;


const IndexPage = () => (
  <Layout>
    <SEO title="Kacper Kula" />
    <HeroSection>
      <Canvas />
      <HeroContent>
        <ContentWrapper>
        <IsometricContainer>
        <h1>Hi, I'm Kacper Kula</h1>
        </IsometricContainer>
        <h2>JavaScript developer with over 14 years of experience. <a href="https://cv.kulak.technology/">You can find my CV here.</a></h2>
        </ContentWrapper>
      </HeroContent>
    </HeroSection>
    <PaddedSection background="#01081a" color="#EEE" padding="90vw" disableFullHeight>
      <AboutMe />
    </PaddedSection>
    <PaddedSection background="#09020d" color="#EEE">
      <h1>Timeline</h1>
      <TimeLine />
    </PaddedSection>
    <PaddedSection background="#0d1213" color="#EEE" padding="90vw">
      <h1>Articles</h1>
      <Articles />
    </PaddedSection>
  </Layout>
)

export default IndexPage
