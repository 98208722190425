import Section from "./section";
import styled from "@emotion/styled";

export default styled(Section)`
    display: flex;
    align-items: center;
    align-content: center;
    justify-content: center;
    flex-direction: column;
    background: #0c0f37;
    color: #FFF;
    h1 {
        font-size: 10em;
        padding: 0.1em;
    }
    h2 {
        padding: 0.1em;
        font-size: 2.5em;
    }

    @media screen and (max-width: 800px) {
        h1 {
            font-size: 5em;
        }
        h2 {
            font-size: 1.5em;
            margin: 0.4em;
        }
    }

    a {
        color: inherit;
    }
`;