import React, { useEffect, useRef } from "react";
import styled from "@emotion/styled";

const StyledCanvas = styled.canvas`
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    max-width: 100%;
    z-index: 0;
`;

const normalize = (x, y) => 2*(x / y) - 1;

const shift = (t, x) => {
    return ((Math.sin(t*Math.PI) + x + 1) % 2) - 1; 
}

const clip = (v, min, max) => Math.min(max, Math.max(min, v));

const position = {
    x: 100,
    y: 100,
};

const setPos = (e) => {
    position.x = e.clientX;
    position.y = e.clientY;
}

const draw = (ctx, width, height, t, scrollTop) => {

    ctx.clearRect(0, 0, width, height);
    ctx.strokeStyle = 'red';
    ctx.lineWidth = 8;

    const multiplier = 0.75;

    const color = (x, y, l) => 'rgb(' + [
        clip(multiplier * (20 + 18 * (2+x+y) + l), 0, 255),
        clip(multiplier * (12*y+12*x*x + l), 0, 255),
        clip(multiplier * (120 + l), 0, 255)
    ].map(x => parseInt(x)).join(',') + ')';
    
    let side = Math.floor(width / 100) + 30 * Math.cos(t/50000)
    if (side < 20) {
        side = 20;
    }
    const shift = 500;
    const starting = { x: 0, y: 0};
    const currentShift = shift/2 + Math.sin(t/10000) * shift/2;
    while (starting.y <= height) {
        while (starting.x - currentShift <= width) {
            const x = starting.x - currentShift;
            ctx.beginPath();
            ctx.moveTo(x, starting.y);
            ctx.lineTo(x + side, starting.y);
            ctx.lineTo(x + side * Math.cos(1.0472), starting.y + side * Math.sin(1.0472));
            ctx.lineTo(x, starting.y);

            const dX = position.x - x;
            const dY = position.y - starting.y;

            const distance = 10000 / (Math.sqrt(dX*dX + dY*dY) + 0.0001);
            ctx.strokeStyle = color(normalize(x, width), normalize(starting.y, height), distance * 0.5);
            ctx.fillStyle = color(normalize(x, width), normalize(starting.y, height), 0);
            ctx.stroke();
            // ctx.fill();
            starting.x += side;
        }
        starting.y += side * Math.sin(1.0472);
        starting.x = (starting.x + side / 2) % side - side;
    }
    ctx.fillStyle = 'rgba(0,0,0,' + scrollTop*1.2 + ')';
    ctx.fillRect(0, 0, width, height);
}

const Canvas = () => {

    const ref = useRef(); 

    useEffect(() => {
        if (!ref.current || !ref.current.getContext) {
            return;
        }
        const ctx = ref.current.getContext("2d");
        let width = 0;
        let height = 0;
        const onResize = () => {
            const rect = ref.current.parentElement.getBoundingClientRect()
            width = rect.width;
            height = rect.height;
            ref.current.width = width;
            ref.current.height = height;
        };
        onResize();
        const html = document.querySelector('html');

        const intervalHandle = setInterval(() => draw(ctx, width, height, Date.now(), html.scrollTop / height), 16);
        document.addEventListener("mousemove", setPos);


        window.addEventListener("resize", onResize);

        return () => {
            clearInterval(intervalHandle);
            document.removeEventListener("mousemove", setPos);
            window.removeEventListener("resize", onResize);
        }


    }, [ref]);


    return <StyledCanvas ref={ref} />

}

export default Canvas;