import React from "react";
import styled from "@emotion/styled";
// import BinaryImage from "../images/binary-code.svg";
import Selfie from "../images/selfie.png";


const SideBySide = styled.div`
    display: flex;
    height: 100%;
    font-size: 1.4em;
    line-height: 1.5em;

    @media screen and (max-width: 1400px) {
        display: block;
    }

    a {
        color: inherit;
        font-weight: bold;
    }

    h2 {
        font-size: 3em;
        text-shadow: 5px 0 0 #000;
    }

    align-items: center;
    > div {
        flex: 1;
        img {
            margin: auto 1em;
        }
    }
`;

const AlignBottom = styled.div`
    align-items: bottom;
    > img {
        display: block;
    }
`;

export const AboutMe = () => {
    return <SideBySide>
        <div>
            <h2>About Me</h2>
            <p>I am a senior web developer based in London, UK. Throughout my 15 years of programming career I have worked in various technologies on the frontend, backend, and mobile projects including small landing pages, e-commerce stores, scalable backends, dashboards, Android map-based mobile app, React Native cross-platform app, and many more. In my spare time, I love to play guitar and synthesizers, take photographs and develop my side-projects.</p>
            <p>I am open to new projects and offers - <a href="mailto:hello@kulak.at">please drop me a message</a>.</p>
        </div>
        <AlignBottom><img src={Selfie} /></AlignBottom>
    </SideBySide>
}