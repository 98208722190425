import { graphql, useStaticQuery } from "gatsby"

export const useMediumData = () => {
    return useStaticQuery(graphql`
        query MyQuery {
            allMediumArticles(sort: {order: DESC, fields: Publish_Date}) {
            edges {
                node {
                id
                Title
                Picture
                Link
                Publish_Date
                }
            }
            }
        }
      
    `);
}